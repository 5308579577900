import React from "react"
import { Route, Switch } from "react-router-dom"

const appRoutesConfig: any = []

const appRoutes = () => {
  return (
    <Switch>
      <React.Suspense fallback={"Loading"}>
        {appRoutesConfig.map(({ path, component }: any) => (
          <Route path={path} component={component} />
        ))}
      </React.Suspense>
    </Switch>
  )
}

export default appRoutes
