import envJSON from './env.json'

export interface Config {
  external_api?: ExternalAPI;
  main_external_api?: string;
  main_external_socket?: string;
  has_site?: boolean;
  default_language?: string;
  secure_home?: string;
  host?: string;
  available_hosts?: AvailableHosts;
  google_analytics?: string;
  customs?: Customs;
  issue_collector?: string;
}

export interface AvailableHosts {
  [key: string]: AvailableHost;
}

export interface AvailableHost {
  slug: string;
}

export interface Customs {
  sis?: string;
  lms?: string;
}

export interface ExternalAPI {
  backend: API;
  main_socket: API;
}

export interface API {
  url: string;
}


const env: Config = envJSON

export const external_api = env?.external_api || {}
export const main_external_api = env?.main_external_api || null
export const main_external_socket = env?.main_external_socket || null

export const default_language = env?.default_language || 'es'
export const has_site = env?.has_site || true
export const secure_home = env?.secure_home || null
export const google_analytics = env?.google_analytics || null
export const customs = env?.customs || {}
export const host = env?.host || window.location.hostname;
export const available_hosts = env?.available_hosts || {};

export const secure_home_path = secure_home || '/home'
export const has_secure = secure_home && typeof secure_home === 'string' ? true : false

export const issue_collector = env?.issue_collector || undefined

