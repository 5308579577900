import React from "react"
import { Route, Switch } from "react-router-dom"

import * as env from '@/config/globals'

const DefaultSite = React.lazy(() => import("@/components/sites/default"))

const landingRoutesConfig: any = {
  impactReport: {
    path: '/',
    component: React.lazy(() => import("@/components/sites/impactReport")),
  },
  kuepaHome: {
    path: '/',
    component: React.lazy(() => import("@/components/sites/kuepa")),
  },
  hiBogota: {
    path: '/',
    component: React.lazy(() => import('@/components/sites/hiBogota'))
  }
}
const siteRoutes = () => {

  const currentHost: string = window.location.hostname
  const isLocalhost: boolean = currentHost === 'localhost' || currentHost === '127.0.0.1'
  const isHost: boolean = currentHost === env?.host
  const currentAvailableHost: env.AvailableHost = env.available_hosts[currentHost]
  console.log('env', env)
  return (
    <React.Suspense fallback={React.Fragment}>
      <Switch>
        {env?.available_hosts && Object.keys(env?.available_hosts).length > 0 && !isLocalhost && !isHost ? (
          <React.Fragment>
            <Route
              path={landingRoutesConfig[currentAvailableHost?.slug].path}
              component={landingRoutesConfig[currentAvailableHost.slug].component}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Route path="/" component={DefaultSite} />
          </React.Fragment>
        )}
      </Switch>
    </React.Suspense>
  )
}

export default siteRoutes
